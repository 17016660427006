<template>
  <div 
    :class="formClasses"
  >
    <label for="current_value_local_data" style="position: relative">
      {{ $t("titles.current_value_local_data") }}
      <ToolTip :title="$t('hints.current_value_local_data')" />
    </label>
    <input
      type="text"
      class="form-control"
      data-testid="current-value-local-data-label"
      v-model="iValue"
      id="current_value_local_data"
      v-bind:disabled="disabled"
    />
  </div>
</template>

<script>
import ToolTip from "@/components/tooltip.vue";
import messages from "@/i18n/data";

export default {
  name: "InputCurrentValueLocalData",
  i18n: { messages },
  components: {
    ToolTip
  },
  props: {
    value: {
      type: String,
      required: false,
      default: ""
    },
    disabled: {
      type: Boolean,
      required: false,
      default: true
    },
    valid: {
      type: Boolean,
      required: false,
      default: true
    },
    formGroupClass: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {};
  },
  computed: {
    iValue: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.value || null;
      }
    },
    formClasses() {
      let classes = "form-group";

      if (this.formGroupClass) {
        classes += " " + this.formGroupClass;
      }

      if (!this.valid) {
        classes += " has-error"
      }

      return classes
    }
  }
};
</script>

<style scoped>
label {
  display: block;
}
</style>